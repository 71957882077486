* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.card {
	position: relative;
	height: 180px; /* Fixed card height */
	width: 280px !important;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
	transition:
		transform 0.3s ease,
		box-shadow 0.3s ease;
}

.card .checkbox {
	opacity: 1;
}

.card img {
	width: 100%;
	height: 180px;
	object-fit: cover;
}

.card-footer {
	display: flex;
	justify-content: space-between;
	margin-top: 6px;
	gap: 12px;
	height: 50px;
	margin-left: 4px;
}

.card-footer-left {
	display: flex;
	flex-direction: row;
	gap: 12px;
}

.card-footer-title {
	width: 220px;
	font-size: 14px;
	margin: 0px !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
}

.card-footer-description {
	width: 220px;
	font-size: 12px;
	color: var(--color-text-grey);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: 0px !important;
}

.card-live-container {
	display: flex;
	align-items: center;
	gap: 6px;
	position: absolute;
	background-color: var(--white-color);
	border-radius: 4px;
	padding: 4px 8px;
	left: 10px;
	top: 10px;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.card-live-container p {
	font-size: 12px;
	font-weight: bold;
}

.fade-global-only-enter {
  @apply opacity-0 translate-y-2 scale-95;
}

.fade-global-only-enter-active {
  @apply opacity-100 translate-y-0 scale-100 duration-300 transition-all ease-in-out;
}

.fade-global-only-exit {
  @apply opacity-100 translate-y-0 scale-100;
}

.fade-global-only-exit-active {
  @apply opacity-0 translate-y-2 scale-95 duration-300 transition-all ease-in-out;
}
.card-gallery__container {
	& .card-gallery__item {
		border-radius: 4px;

		padding: 4px;

		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

		position: relative;

		& .btn--select {
			position: absolute;
			top: 8px;
			right: 8px;

			z-index: 1;

			opacity: 0;

			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		&.card-gallery__item--selectable {
			cursor: pointer;

			&:hover {
				& .btn--select {
					opacity: 1;
				}
			}
		}

		&.card-gallery__item--selected {
			& .btn--select {
				opacity: 1;
			}
		}
	}
}

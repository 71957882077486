.if-cms-sidebar-icon {
	padding: 8px;
	box-shadow:
		0 2px 4px 0 rgba(0, 0, 0, 0.2),
		0 3px 9px 0 rgba(0, 0, 0, 0.1);
	//   box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	border-radius: 8px;
	color: #c01d1c;
}

.ant-menu-item-selected {
	box-shadow:
		0 2px 4px 0 rgba(0, 0, 0, 0.2),
		0 3px 9px 0 rgba(0, 0, 0, 0.1);

	& .if-cms-sidebar-icon {
		background-color: #c01d1c;
		box-shadow: none;
		color: #fff !important;
	}
}

.ant-menu-submenu .ant-menu-sub .ant-menu-item {
	padding-left: 8px !important;
	padding-right: 8px !important;

	&:hover {
		background-color: #fff2f0 !important;
		color: #c01d1c !important;
	}
}

.if-cms-logo {
	img {
		cursor: pointer;
		width: 100px;
		height: 60px;
	}
}

#if-cms-layout-sider {
	background: transparent;

	.ant-layout-sider-children {
		position: relative;

		& .if-cms-logo {
			z-index: 1;
			background: #001529;

			position: sticky;
			top: 0;

			height: 80px;
			padding: 8px;

			display: flex;
			justify-content: center;
			align-items: center;

			img {
				// width: 100%;
			}
		}
	}

	.ant-menu.ant-menu-root {
		border: 0;
		padding: 8px;

		& .ant-menu-item,
		& .ant-menu-submenu .ant-menu-submenu-title {
			padding-left: 8px !important;
			padding-right: 8px !important;

			&:hover {
				background-color: #fff2f0 !important;
				color: #c01d1c !important;
			}
		}

		& .ant-menu-submenu .ant-menu-sub {
			margin-left: 16px;
			border-radius: 12px;
		}
	}
}

#if-cms-layout-sider.ant-layout-sider-collapsed {
	.if-cms-logo {
		img {
			width: 60px;
			height: 40px;
		}
	}

	.if-cms-sidebar-icon {
		margin-left: 4px;
	}
}
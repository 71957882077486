.data-gallery-container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	& .item-container {
		height: 100%;

		border: 1px solid #ccc;
		padding: 8px;
		border-radius: 4px;

		position: relative;

		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	& .check-icon {
		cursor: pointer;

		position: absolute;
		top: -8px;
		right: -8px;

		opacity: 0;
		background-color: var(--white-color);
		color: var(--color-text-grey);
		font-size: 20px;

		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	& .item-container:hover {
		& .check-icon {
			opacity: 1;
		}
	}

	& .item-container.selected {
		border: 1px solid var(--primary);

		& .check-icon {
			opacity: 1;

			color: var(--primary);
		}
	}
}

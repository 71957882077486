* {
	--img-height: 208px;
	--card-width: 230px;
	--card-height: 210px;
}

.image-gallery {
	box-sizing: border-box;

	.image-card {
		display: flex;
		flex-direction: column;

		.text {
			position: absolute;
			bottom: 0;
			height: 35px;
			overflow: scroll;
		}
	}
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.outerView {
	padding-bottom: 20;
	padding-right: 20;
}

.card {
	display: flex;
	flex-direction: column;
	width: var(--card-width) !important;
	border: 1px solid #ccc;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

.card-details {
	flex-grow: 1;
}

.card-details h2 {
	margin: 0;
	font-size: 1.2rem;
}

.card-details p {
	margin: 0;
	color: #666;
}

.card-image-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: var(--img-height) !important;
	object-fit: contain;
	border: 1px solid #ccc;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	overflow: hidden;
}

.card-image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/* Cards container */
.ReactGridGallery div {
	text-align: center;
	justify-content: center;
}

.ReactGridGallery_tile {
	border-radius: var(--card-border-radius) !important;
	height: var(--card-height);
	width: var(--card-width);
	background: var(--white) !important;
	padding: 20px;
}

.ReactGridGallery_tile img {
	height: var(--img-height) !important;
	width: var(--card-width) !important;
	object-fit: cover;
	// border-radius: 8px;
}

.ReactGridGallery_tile-viewport {
	display: flex;
	border-radius: var(--card-border-radius);
	box-shadow:
		rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
		rgba(27, 31, 35, 0.15) 0px 0px 0px 1.3px;
}

.ReactGridGallery_tile-overlay {
	border-radius: var(--card-border-radius);
}

.ReactGridGallery_tile svg {
	fill: var(--primary);
}

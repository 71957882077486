@tailwind utilities;

:root {
	--primary: #c01d1c;
	--secondary: #0d2e69;
	--white-color: #fff;
	--grey-color: #959595;
	--black-color: #000000;
	--card-border-radius: 6px;
	--image-card-height: 240px;
	--color-text-grey: #787878;
	--color-gray-disabled: #0000000a;
}



body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 400px;

	.fade-global-only-enter {
		@apply opacity-0 translate-y-2 scale-95;
	}

	.fade-global-only-enter-active {
		@apply opacity-100 translate-y-0 scale-100 duration-300 transition-all ease-in-out;
	}

	.fade-global-only-exit {
		@apply opacity-100 translate-y-0 scale-100;
	}

	.fade-global-only-exit-active {
		@apply opacity-0 translate-y-2 scale-95 duration-300 transition-all ease-in-out;
	}
}


;

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Global Classes
.global-title {
	h2 {
		color: var(--secondary);
		margin-bottom: 0 !important;
	}

	p {
		margin-top: 5px;
	}
}

.ck-powered-by {
	display: none;
}

.ant-picker-cell-disabled {
	cursor: not-allowed !important;

	&.ant-picker-cell-range-start {
		&::before {
			inset-inline-start: 50%;
			background: #fff2f0 !important;
		}

		& .ant-picker-cell-inner {
			background: var(--primary) !important;
			color: #fff !important;
		}
	}

	&.ant-picker-cell-in-range {
		&::before {
			background: #fff2f0 !important;
		}

		& .ant-picker-cell-inner {
			color: rgba(0, 0, 0, 0.4) !important;
		}
	}
}

.ant-spin-dot-holder {
	color: var(--primary) !important;
}

